import React from "react"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"

// This component is basically empty because it just renders the Footer which contains the signup form.
const GetStarted = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Get started"
        pathname="/get-started"
        description="Send us your contact details and we’ll be in touch to arrange a demo of Carebit."
      />
    </Layout>
  )
}

export default GetStarted
